<template>
<div class="main">
    <div class="header">
        <div class="htitle">我的云盘</div>
        <div class="close">
            <router-link to="/efficiency">
                <el-image style="width: 3.2vh; height: 3vh" fit="cover" :src="require('@/assets/close.png')"></el-image>
            </router-link>
        </div>
        <div>
            <router-view></router-view>
            <div v-if="uploader">
                <uploader :options="options" class="uploader-example">
                    <uploader-unsupport></uploader-unsupport>
                    <uploader-drop>
                        <p>Drop files here to upload</p>
                    </uploader-drop>
                    <uploader-btn>select files</uploader-btn>
                    <!-- <uploader-btn :attrs="attrs">select images</uploader-btn> -->
                    <uploader-btn :directory="true">select folder</uploader-btn>

                    <uploader-list></uploader-list>
                </uploader>
            </div>

        </div>
        <div class="footer">
            <div @click="showUploader(true)">
                <router-link to="/cloud" >
                    文件上传
                </router-link>
            </div>
            <div @click="showUploader(false)">
                <router-link to="/file" >
                    文件列表
                </router-link>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import url from "@/serviceAPI.config.js";

export default {
    data() {
        return {
            uploader: true,
            options: {
                // https://github.com/simple-uploader/Uploader/tree/develop/samples/Node.js
                target: url.cloud,
                testChunks: false,
                autoStart: false,
            },
            // attrs: {
            //     accept: 'image/*' 文件筛选
            // }
        }
    },
    methods: {
        showUploader(judge) {
            this.uploader = judge
        }
    },
}
</script>

<style scoped>
/* .main{
    display: flex;
} */
/* header部分 */
.header {
    position: relative;
    height: 10vh;
    background-color: rgb(250, 250, 251);
}

.htitle {
    line-height: 10vh;
    font-size: 1rem;
}

.close {
    position: absolute;
    top: 3.3vh;
    right: 5vw;
}

/* body部分 */
.body {
    margin: 0 5vw;
    background-color: rgb(182, 82, 82);
}

a {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-decoration: none;
    color: #0071E3;
}

/* upload */
.uploader-btn {
    background-color: #409eff;
    color: #fff;
    border-radius: 4px;
    height: 30px;
    width: 30vw;
    font-size: 1rem;
    line-height: 30px;
    border: none;
    margin: 10px;
}

.uploader-drop {
    height: 15vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.uploader-example {
    width: 80vw;
    height: 72vh;
    padding: 15px;
    margin: 10px auto 0;
    font-size: 1rem;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, .2); */
    overflow: hidden;
}

.uploader-example .uploader-btn {
    margin-right: 4px;
    background-color: #0071E3;
}

.uploader-example .uploader-list {
    max-height: 80vh;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
}

.footer {
    background-color: #F7F7F7;
    height: 10vh;
    width: 100vw;
    position: absolute;
    top: 90vh;
    display: -webkit-flex;
    /* Safari */
    display: flex;
    justify-content: space-around;
    align-items: center;
}
</style>
